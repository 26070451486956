<template>
  <div>
    <div :class="{ disabled: disabled }" class="form-item pb-2">
      <label class="form-item__label info-popup">
        {{ $t("platform.settings.blackout_title") }}
       
          <svg v-svg v-tooltip="{
            content: $t('platform.settings.description.native_web_push.blackout_title'),
            trigger: $isMobile ? 'click':'hover',
          }"
          symbol="info" size="0 0 18 18" role="info" />
     
      </label>
      <div class="form-item__field">
        <input :disabled="disabled" type="text" v-model.trim="blackout_title" />
        <small
          v-if="
            error.blackout_title.$error &&
            (!error.blackout_title.minLength || blackout_title === '')
          "
          class="error"
          >{{ $t("min_len_pass", { number: "3" }) }}</small
        >
        <small
          v-if="
            error.blackout_title.$error &&
            (!error.blackout_title.maxLength || blackout_title === '')
          "
          class="error"
          >{{ $t("max_len_pass", { number: "120" }) }}</small
        >
      </div>
    </div>
    <div :class="{ disabled: disabled }" class="form-item pb-2">
      <label class="form-item__label info-popup">
        {{ $t("platform.settings.blackout_text") }}
        
          <svg v-svg v-tooltip="{
            content: $t('platform.settings.description.native_web_push.blackout_text'),
            trigger: $isMobile ? 'click':'hover',
          }"
          symbol="info" size="0 0 18 18" role="info" />
      
      </label>
      <div class="form-item__field">
        <input :disabled="disabled" type="text" v-model.trim="blackout_text" />
        <small
          v-if="
            error.blackout_text.$error &&
            (!error.blackout_text.minLength || blackout_text === '')
          "
          class="error"
          >{{ $t("min_len_pass", { number: "3" }) }}</small
        >
        <small
          v-if="
            error.blackout_text.$error &&
            (!error.blackout_text.maxLength || blackout_text === '')
          "
          class="error"
          >{{ $t("max_len_pass", { number: "120" }) }}</small
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BlackoutSettings",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    blackout_title: {
      get() {
        return this.title;
      },
      set(newVal) {
        this.$emit("setTitle", newVal);
      },
    },
    blackout_text: {
      get() {
        return this.text;
      },
      set(newVal) {
        this.$emit("setText", newVal);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  label,
  input {
    opacity: 0.6;
  }
}
</style>