<template>
  <div class="col-12">
    <div class="row">
      <div class="col-lg-12 col-xl-6">
        <RequestType v-model="request_type" />
        <DragAndDrop v-model="siteImg" />

        <CheckboxList v-if="request_type === 'button'" class="mt-4 pt-2 mb-4" v-model="request_size" :list="request_size_list" :titlePath="'platform.settings.click_push.size.title'" :path="'platform.settings.click_push.size.'" />

        <div class="form-item" v-if="request_type === 'safari' || request_type === 'lightbox' || request_type === 'button'">
          <div class="form-item__label">
            {{ $t(`platform.settings.inpage.title`) }}
          </div>
          <div class="form-item__field">
            <input type="text" maxlength="100" :class="{ invalid: $v.request_title.$invalid }" v-model.trim="request_title" />
            <small class="error" v-if="$v.request_title.$invalid">
              {{ $t("title_pass", { min: 3, max: 100 }) }}
            </small>
          </div>
        </div>
        <div class="form-item" v-if="!(request_type === 'button' && request_size === 'large')">
          <div class="form-item__label">
            {{ $t(`platform.settings.click_push.request_text`) }}
          </div>
          <div class="form-item__field">
            <textarea type="text" maxlength="255" :class="{ invalid: $v.request_text.$invalid }" v-model.trim="request_text" />
            <small class="error" v-if="$v.request_text.$invalid">
              {{ $t("text_pass", { min: 3, max: 255 }) }}
            </small>
          </div>
        </div>
        <div class="colors-wrapper mb-4">
          <div class="col-sm-12 col-md-4" v-for="(el, key) in colorList" :key="key">
            <div class="form-item__label">
              {{ $t(`platform.settings.click_push.colors.${el}`) }}
            </div>
            <label class="color-block" :for="`color-input-${el}`">
              {{ getColor(el) }}
              <input :id="`color-input-${el}`" type="color" :value="getColor(el)" @change="setColor(el, $event)" />
            </label>
          </div>
        </div>
        <CheckboxList v-if="request_type === 'standard' || request_type === 'button'" class="mt-3 mb-3" v-model="request_location" :list="requestLocationList" :titlePath="'platform.settings.click_push.location.title'" :path="'platform.settings.click_push.location.'" />

        <div class="form-item" v-if="!(request_type === 'button' && request_size === 'large')">
          <label class="form-item__label">
            {{ $t("platform.settings.click_push.button_text") }}
          </label>
          <custom-multiselect class="multiselect-wrapper multiselect-w-100 form-item__field" v-model="button_text_lang" :list="button_text_land_list" :allowEmpty="false">
            <template v-slot:block="props">
              <span>{{ props.value.val === "custom" ? $t(`${props.value.name}`) : props.value.name }}</span>
            </template>
            <template v-slot:list="props">
              <span>{{ props.value.val === "custom" ? $t(`${props.value.name}`) : props.value.name }}</span>
            </template>
          </custom-multiselect>
        </div>
        <div v-if="button_text_lang.val === 'custom' && !(request_type === 'button' && request_size === 'large')" class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="form-item__label">
              {{ $t(`platform.settings.click_push.apply_button`) }}
            </div>
            <div class="form-item__field">
              <input type="text" :class="{ invalid: $v.apply_button.$invalid }" v-model.trim="apply_button" maxlength="30" />
              <small class="error" v-if="$v.apply_button.$invalid">
                {{ $t("text_pass", { min: 2, max: 30 }) }}
              </small>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="form-item__label">
              {{ $t(`platform.settings.click_push.reject_button`) }}
            </div>
            <div class="form-item__field">
              <input type="text" :class="{ invalid: $v.reject_button.$invalid }" v-model.trim="reject_button" maxlength="30" />
              <small class="error" v-if="$v.reject_button.$invalid">
                {{ $t("text_pass", { min: 2, max: 30 }) }}
              </small>
            </div>
          </div>
        </div>
        <div class="mb-4 mt-4">
          <h3>
            {{ $t("platform.settings.click_push.show_request_conditions") }}
          </h3>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <Counter v-model="appearance_delay" :max="180" :min="0" :title="'platform.settings.click_push.appearance_delay'" />
          </div>
          <div class="col-12 col-md-4">
            <Counter v-model="close_delay" :max="60" :min="0" :title="'platform.settings.click_push.close_delay'" />
          </div>
          <div class="col-12 col-md-4">
            <Counter v-model="show_request_visits" :max="180" :min="1" fit :title="'platform.settings.click_push.show_request_by_visits'" :description="'platform.settings.click_push.request_by_visits_desc'" />
          </div>
        </div>
        <div class="mt-4 mb-4">
          <h3 class="mb-3">{{ $t("platform.landing.title") }}</h3>
          <div class="form-item">
            <label class="form-item__label">
              {{ $t("platform.settings.click_push.landing.title") }}
            </label>
            <custom-multiselect v-model="current_landing" :list="landing_list">
              <template v-slot:block="props">
                <span v-if="props.value">{{ $i18n.locale === "ru" ? props.value.name_ru : props.value.name_en }}</span>
                <span v-else>
                  {{ $t("platform.settings.click_push.landing.placeholder") }}
                </span>
              </template>
              <template v-slot:list="props">
                <div class="landing-select">
                  <img width="56" height="29" :src="`${props.value.image}`" alt="landing image" />
                  <span>{{ $i18n.locale === "ru" ? props.value.name_ru : props.value.name_en }}</span>
                  <button @click.stop="openLandModal(props.value)">
                    <svg v-svg role="presentation" width="14" height="12" symbol="watch"></svg>
                    {{ $t("platform.settings.click_push.landing.button_text") }}
                  </button>
                </div>
              </template>
            </custom-multiselect>
          </div>

          <DisplaySettings v-if="featureFlags.includes('enable-display-settings')" :displaySettings="displaySettings" :disableOsVersions="osVersions" />
        </div>
        <div class="d-flex">
          <button @click.prevent="sendRequest" :disabled="!current_landing || !siteImg || !checkInputsValidity" class="btn mb-2 _mob100 d-none d-xl-block">
            {{ $t("save") }}
          </button>
          <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green mb-2 ml-2 _mob100 d-none d-xl-block"><svg v-svg size="0 0 20 13" symbol="code"></svg> {{ $t("platforms.table.get_code") }}</button>
        </div>
      </div>
      <div class="col-sm-12 col-xl-6 d-none-mob">
        <PreviewClick
          :request_type="request_type"
          :request_title="request_title"
          :request_text="request_text"
          :button_size="request_size"
          :button_color="getColor('button')"
          :img="siteImg"
          :bg_color="getColor('bg')"
          :icon_color="getColor('icon')"
          :text_color="getColor('text')"
          :apply_button_text="apply_button"
          :reject_button_text="reject_button"
          :request_location="request_location"
          :loading="loading"
        />
      </div>
      <div class="col-12 mt-3 mb-2 d-xl-none">
        <button @click.prevent="sendRequest" :disabled="!current_landing || !siteImg || !checkInputsValidity" class="btn _mob100">
          {{ $t("save") }}
        </button>
        <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green ml-2 _mob100"><svg v-svg size="0 0 20 13" symbol="code"></svg> {{ $t("platforms.table.get_code") }}</button>
      </div>
    </div>
    <modal @closed="clearLandingModal" :width="780" name="landModal" height="auto" :scrollable="true" classes="creative-modal" :shiftY="0">
      <LandingModal :landing="currentLanding" @closeModal="closeLandingModal" />
    </modal>
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="platform" :showSettingsLink="false" @closeModal="closeModal" />
    </modal>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import CheckboxList from "../CheckboxList.vue";
import DisplaySettings from "../DisplaySettings.vue";
import Counter from "../Counter.vue";
import DragAndDrop from "../DragAndDrop.vue";
import RequestType from "../RequestType.vue";
import PreviewClick from "../PreviewClick.vue";
import LandingModal from "@/components/LandingModal";
import LandingMixin from "@/mixins/landModal";
import ServiceSubAccs from "@/api/subaccsService";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import siteSettingsService from "@/api/siteSettingsService";
import { mapGetters } from "vuex";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";

export default {
  name: "ClickPush",
  props: {
    platform: {
      type: Object,
    },
  },
  components: {
    RequestType,
    DragAndDrop,
    Counter,
    CheckboxList,
    LandingModal,
    PreviewClick,
    DisplaySettings,
  },
  mixins: [LandingMixin, validationMixin, platformSettingsGetCode],
  validations: {
    request_title: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    request_text: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(255),
    },
    reject_button: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30),
    },
    apply_button: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(30),
    },
  },
  data() {
    return {
      request_type: "standard",
      siteImg: "",
      request_title: "",
      request_text: "",
      icon_color: "#FFFFFF",
      bg_color: "#FFFFFF",
      button_color: "#37807D",
      text_color: "#141414",
      button_text_lang: null,
      reject_button: null,
      apply_button: null,
      button_text_land_list: [
        {
          name: "platform.settings.click_push.custom_button_text",
          val: "custom",
        },
        {
          name: "English",
          val: "en",
          apply_button: "Allow",
          reject_button: "Don't allow",
        },
        {
          name: "Українська",
          val: "uk",
          apply_button: "Дозволити",
          reject_button: "Заборонити",
        },
        {
          name: "Русский",
          val: "ru",
          apply_button: "Разрешить",
          reject_button: "Запретить",
        },
        {
          name: "Français",
          val: "fr",
          apply_button: "Autoriser",
          reject_button: "Interdire",
        },
        {
          name: "Português",
          val: "pt",
          apply_button: "Permitir",
          reject_button: "Não permitir",
        },
        {
          name: "Español",
          val: "es",
          apply_button: "Permitir",
          reject_button: "Bloquear",
        },
        {
          name: "Deutsch",
          val: "de",
          apply_button: "Erlauben",
          reject_button: "Nicht erlauben",
        },
        {
          name: "Italiano",
          val: "it",
          apply_button: "Consenti",
          reject_button: "Blocca",
        },
        {
          name: "中文",
          val: "zh",
          apply_button: "允许",
          reject_button: "禁止",
        },
        {
          name: "Türkçe",
          val: "tr",
          apply_button: "Izin Ver",
          reject_button: "Engelle",
        },
        {
          name: "Polski",
          val: "pl",
          apply_button: "Pozwól",
          reject_button: "Zablokuj",
        },
      ],
      show_request_visits: 1,
      appearance_delay: 0,
      close_delay: 0, // closeDelay

      request_size_list: ["small", "large"],
      request_size: "small",
      request_location: "left",

      landing_list: [],
      current_landing: null,
      osVersions: [],
      displaySettings: {
        disableDeviceSell: "",
        disableOsSell: "",
        notShowReff: false,
        disableYandex: false,
      },

      loading: true,
    };
  },
  watch: {
    request_type: {
      handler(val) {
        if (val === "button" && this.request_location === "center") {
          this.request_location = "left";
        }
      },
    },
    button_text_lang: {
      handler(val) {
        if (val.val !== "custom") {
          this.apply_button = val.apply_button;
          this.reject_button = val.reject_button;
        }
      },
    },
  },
  created() {
    const id = this.$route.params.id;

    this.request_title = this.$t("platform.settings.click_push.request_title_default", "en");
    this.request_text = this.$t("platform.settings.click_push.request_text_default", "en");
    this.button_text_lang = this.button_text_land_list.find((el) => el.val === this.$i18n.locale);

    this.apply_button = this.button_text_land_list.find((el) => el.val === this.button_text_lang.val).apply_button;
    this.reject_button = this.button_text_land_list.find((el) => el.val === this.button_text_lang.val).reject_button;

    siteSettingsService.getOsVersions().then((res) => {
      if (res && res.status === 200) {
        this.osVersions = res.data;
      }
    });

    ServiceSubAccs.getLandings()
      .then((res) => {
        if (res && res.status === 200) {
          this.landing_list = res.data;
        }
      })
      .then(() => {
        siteSettingsService
          .getClickPush(id)
          .then((res) => {
            if (res && res.status === 200) {
              this.request_type = res.data.variant === "floatingButton" ? "button" : res.data.variant || this.request_type;
              this.siteImg = res.data.iconUrl || this.siteImg;
              this.current_landing = this.landing_list.find((el) => el.id === res.data.landing) || null;
              this.appearance_delay = res.data.delay || this.appearance_delay;
              this.show_request_visits = res.data.showOnVisit || this.show_request_visits;
              this.close_delay = res.data.closeDelay || this.close_delay;
              this.request_location = res.data.position || this.request_location;
              this.request_size = res.data.size || this.request_size;
              this.request_title = res.data.title || this.request_title;
              this.request_text = res.data.text || this.request_text;
              this.button_text_lang = this.button_text_land_list.find((el) => el.val === res.data.buttons.lang) || this.button_text_lang;
              if (this.button_text_lang.val === "custom") {
                this.reject_button = res.data.buttons.deny || this.reject_button;
                this.apply_button = res.data.buttons.allow || this.apply_button;
              }
              this.bg_color = res.data.backgroundColor || this.bg_color;
              this.text_color = res.data.textColor || this.text_color;
              this.button_color = res.data.buttons.color || this.button_color;
              this.icon_color = res.data.iconColor || this.icon_color;

              this.displaySettings = {
                disableDeviceSell: res.data.display_settings?.disable_devices || [],
                disableOsSell: this.osVersions.filter((el) => res.data.display_settings?.disable_os.includes(el.value)) || [],

                notShowReff: res.data.display_settings?.disable_no_referer || false,
                disableYandex: res.data.display_settings?.disable_yandex_browser || false,
              };
            }
          })
          .finally(() => (this.loading = false));
      });
  },
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
    colorList() {
      if (this.request_type === "button") {
        if (this.request_size === "small") return ["icon", "button"];
        else return ["text", "button"];
      } else if (this.request_type === "safari") {
        return [];
      } else {
        return ["bg", "text", "button"];
      }
    },
    requestLocationList() {
      if (this.request_type === "button") {
        return ["left", "right"];
      } else {
        return ["left", "center", "right"];
      }
    },
    checkInputsValidity() {
      let textCheck = true;
      let titleCheck = true;
      let applyCheck = true;
      let rejectCheck = true;
      if (this.$v.request_title.$invalid) {
        if (this.request_type === "lightbox" || this.request_type === "safari" || this.request_type === "button") {
          titleCheck = false;
        }
      }
      if (this.$v.request_text.$invalid) {
        if (!(this.request_type === "button" && this.request_size === "large")) {
          textCheck = false;
        }
      }
      if (this.button_text_lang.val === "custom") {
        if (this.$v.reject_button.$invalid) rejectCheck = false;
        if (this.$v.apply_button.$invalid) applyCheck = false;
      }

      return textCheck && titleCheck && applyCheck && rejectCheck;
    },
    getData() {
      const payload = {
        id: this.$route.params.id,
        type: this.request_type === "button" ? "floatingButton" : this.request_type,
        img: this.siteImg,
        landing_id: this.current_landing ? this.current_landing.id : null,
        appearance_delay: this.appearance_delay,
        show_request: this.show_request_visits,
        close_delay: this.close_delay,
        button_lang: this.button_text_lang.val,
        reject_button: this.reject_button,
        apply_button: this.apply_button,

        display_settings: this.featureFlags.includes("enable-display-settings")
          ? {
              disable_devices: this.displaySettings.disableDeviceSell || [],
              disable_os: this.displaySettings.disableOsSell.map((el) => el.value) || [],
              disable_no_referer: this.displaySettings.notShowReff,
              disable_yandex_browser: this.displaySettings.disableYandex,
            }
          : undefined,
      };
      switch (this.request_type) {
        case "standard":
          payload.text = this.request_text;
          payload.bg_color = this.bg_color;
          payload.text_color = this.text_color;
          payload.button_color = this.button_color;
          payload.location = this.request_location;
          break;

        case "lightbox":
          payload.title = this.request_title;
          payload.text = this.request_text;
          payload.bg_color = this.bg_color;
          payload.text_color = this.text_color;
          payload.button_color = this.button_color;
          break;

        case "panel":
          payload.text = this.request_text;
          payload.bg_color = this.bg_color;
          payload.text_color = this.text_color;
          payload.button_color = this.button_color;
          break;

        case "safari":
          payload.title = this.request_title;
          payload.text = this.request_text;
          break;

        case "button":
          payload.title = this.request_title;
          payload.text = this.request_text;
          payload.icon_color = this.icon_color;
          payload.text_color = this.text_color;
          payload.button_color = this.button_color;
          payload.location = this.request_location;
          payload.size = this.request_size;
          break;
      }

      return payload;
    },
  },
  methods: {
    sendRequest() {
      this.$v.$touch();
      if (!this.checkInputsValidity) {
        return;
      }
      if (!this.current_landing) return;
      siteSettingsService.setClickPush(this.getData).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if ((res && res.status === 500) || res === undefined) {
          this.$alert({
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: `${this.$t("save_successfully")}`,
          });
          this.allowButton = false;
        }
      });
    },
    getColor(el) {
      return this[`${el}_color`];
    },
    setColor(el, event) {
      this[`${el}_color`] = event.target.value.toUpperCase();
    },
  },
};
</script>
<style lang="scss" scoped>
.colors-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 0;
  margin: 0 -15px;

  .color-block {
    border-radius: 10px;
    background: #f7f7f9;
    font-size: 14px;
    color: #9393a0;
    line-height: 20px;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input[type="color"] {
      margin-left: 8px;
      height: 22px;
      width: 20px;
      border: none;
      background: transparent;
    }

    input[type="color"]::-webkit-color-swatch {
      border: none;
      border-radius: 4px;
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      border: none;
      border-radius: 4px;
      padding: 0;
    }
  }
}

.landing-select {
  display: flex;
  align-items: center;
  gap: 0 16px;

  img {
    border-radius: 4px;
  }

  span {
    font-weight: 500;
    color: #141414;
  }

  button {
    outline: none;
    border: none;
    background: transparent;
    color: #6a6a6a;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 0 4px;
    padding: 6px 0;
  }

  button {
    margin-left: auto;
  }
}
</style>
