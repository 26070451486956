<template>
  <div class="row align-items-center">
    <div class="col-7 col-md-9">
      <div class="form-range">
        <input class="range-input" type="range" :min="min" :max="max" :disabled="disable" v-model="data" id="opacityRange" :style="`background: linear-gradient(90deg, rgba(55,128,125,1) ${fillPercent}%, rgba(239,239,242,1) ${fillPercent}%, rgba(239,239,242,1) 100%);`" />
        <div class="form-range__min">{{ min }}{{ checkVariable ? formatLabel(variable.key) : variable }}</div>
        <div class="form-range__max">{{ max }}{{ checkVariable ? formatLabel(variable.key) : variable }}</div>
      </div>
    </div>
    <div class="col-5 col-md-3">
      <div class="form-item-number _center">
        <input ref="value" type="text" :value="rangeValue" :disabled="disable" @blur="toggleVariable(false)" @focus="toggleVariable(true)" @input="checkInput($event)" />
        <button @click.prevent="upRange" :disabled="range >= max || disable" class="form-item-number__btn icon-a-up" :title="$t('increase')">
          <svg v-svg role="presentation" size="0 0 10 10" symbol="prev" />
        </button>
        <button @click.prevent="downRange" :disabled="range <= min || disable" class="form-item-number__btn icon-a-down" :title="$t('decrease')">
          <svg v-svg role="presentation" size="0 0 10 10" symbol="next" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputRange",
  props: {
    range: {
      type: Number,
      default: 0,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    variable: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      rangeValue: "",
    };
  },
  mounted() {
    this.rangeValue = `${this.range}${this.checkVariable ? this.variable.key : this.variable}`;
  },
  watch: {
    range(val) {
      this.rangeValue = `${val}${this.checkVariable ? this.variable.key : this.variable}`;
    },
  },
  computed: {
    data: {
      get() {
        return this.range;
      },
      set(newVal) {
        this.$emit("input", +newVal);
      },
    },
    checkVariable() {
      return typeof this.variable === "object" && this.variable !== null;
    },
    fillPercent() {
      return (this.data / this.max) * 100;
    },
  },
  methods: {
    checkInput(evt) {
      const value = String(evt.target.value);
      if (isNaN(+value)) {
        evt.target.value = this.rangeValue;
        return;
      } else if (+value > this.max) {
        evt.target.value = this.rangeValue = this.max;
        return;
      } else if (+value < this.min) {
        evt.target.value = this.rangeValue = this.min;
        return;
      } else {
        evt.target.value = Math.trunc(+value.replace(/^(0{2,})/g, "0"));
        this.rangeValue = +value;
      }
    },
    toggleVariable(val) {
      if (val) {
        this.rangeValue = this.range;
        this.$refs.value.value = this.rangeValue;

        this.$refs.value.select();
      } else {
        if (this.rangeValue > this.max) this.$emit("input", this.max);
        else if (this.rangeValue < this.min) this.$emit("input", this.min);
        else if (isNaN(+this.rangeValue)) this.$emit("input", this.range);
        else this.$emit("input", this.rangeValue);

        this.rangeValue = `${this.range}${this.checkVariable ? this.variable.key : this.variable}`;
      }
    },
    formatLabel(el) {
      return this.$t(el);
    },
    upRange() {
      if (this.data <= this.max) this.data++;
    },
    downRange() {
      if (this.data >= this.min) this.data--;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-range {
  position: relative;
  color: #a8a8a8;
  padding: 27px 0;
  .form-range__min {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .form-range__max {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.range-input {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  background: rgb(55, 128, 125);
  border-radius: 4px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.range-input::-webkit-slider-thumb {
  position: relative;
  z-index: 20;
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff url(~@/assets/images/icons/lines.svg) center no-repeat;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border: 0px solid transparent;
  cursor: pointer;
}

.range-input::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff url(~@/assets/images/icons/lines.svg) center no-repeat;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border: 0px solid transparent;
  cursor: pointer;
}

.form-item-number__btn {
  transform: rotate(90deg);
}

.form-item-number__btn:hover {
  svg {
    fill: #fff;
  }
}
</style>
