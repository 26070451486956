<template>
  <div class="form-item">
    <div class="form-item__label" :class="{ 'info-popup': description, fit: fit }">
      {{ $t(title) }}

      <svg
        v-if="description"
        v-svg
        style="min-width: fit-content"
        v-tooltip="{
          content: $t(`${description}`),
          trigger: $isMobile ? 'click' : 'hover',
        }"
        symbol="info"
        size="0 0 18 18"
        role="info"
      />
    </div>
    <div class="form-item__field">
      <div class="form-item-number">
        <input :ref="title" type="number" :value="data" @input="setData($event)" @focus="$refs[title].select()" />
        <button @click="increment" :disabled="value >= max" class="form-item-number__btn icon-a-up" :title="$t('increase')">
          <svg v-svg role="presentation" size="0 0 10 10" symbol="prev" />
        </button>
        <button @click="decrement" :disabled="value <= min" class="form-item-number__btn icon-a-down" :title="$t('decrease')">
          <svg v-svg style="min-width: fit-content" role="presentation" size="0 0 10 10" symbol="next" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Counter",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    fit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", +newVal);
      },
    },
  },
  methods: {
    setData(event) {
      const value = +event.target.value;
      if (value > this.max) {
        event.target.value = this.max;
      } else if (value <= this.min) {
        event.target.value = this.min;
      } else {
        event.target.value = Math.trunc(+String(value).replace(/^(0{2,})/g, "0"));
      }
      this.data = event.target.value;
    },
    decrement() {
      if (this.data >= this.min) this.data--;
    },
    increment() {
      if (this.data <= this.max) this.data++;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-item-number__btn {
  svg {
    transform: rotate(90deg);
  }
  &:hover {
    svg {
      fill: white;
    }
  }
}
.form-item-number {
  small {
    top: 14px;
    right: 50px;
    position: absolute;
  }
}
</style>
