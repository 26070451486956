<template>
  <div class="settings-container">
    <div class="col-12 col-xl-6">
      <div v-if="platform && !platform.allow_native_webpush" class="fields-section warning">
        <div class="attention-field">
          <svg class="message-icon" v-svg symbol="info" size="0 0 17 17" role="info" />
          <p class="mb-0">
            {{ $t("platform.settings.description.native_web_push.warning_info") }}
          </p>
        </div>
      </div>
      <ServiceWorker ref="serviceWorker" :status="service_status" :name="service_name" :error="$v" :description="'platform.settings.description.native_web_push.service_name'" v-model="service_name" @setStatus="setStatus" />
      <InformationBlock class="col-12" :class="{ error: serviceWorkerError }" style="padding-bottom: 32px">
        {{ $t("platform.settings.check_service_info") }}
      </InformationBlock>
      <DelaySettings :class="{ 'disable-delay': checkSubscription }" :disable="checkSubscription" :delay="delay_value" v-model="delay_value" :min="0" :max="60" :variable="{ key: 's' }" :title="'platform.settings.delay'" :description="'platform.settings.description.native_web_push.delay_settings'" />
      <DelaySettings :delay="blackout_range" v-model="blackout_range" :min="0" :max="100" :variable="'%'" :title="'platform.settings.blackout_range'" :description="'platform.settings.description.native_web_push.blackout_settings'" class="pb-2" />
      <BlackoutSettings :disabled="blackout_range === 0" :text="blackout_text" :title="blackout_title" :error="$v" @setTitle="setBlackoutTitle" @setText="setBlackoutText" />
      <SubscriptionRequest :subscription="checkSubscription" v-model="checkSubscription" />

      <DisplaySettings v-if="featureFlags.includes('enable-display-settings')" :displaySettings="displaySettings" :disableOsVersions="osVersions" />

      <button @click.prevent="sendRequest" class="btn mb-2 _mob100">
        {{ $t("save") }}
      </button>
      <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green mb-2 ml-2 _mob100"><svg v-svg size="0 0 20 13" symbol="code"></svg> {{ $t("platforms.table.get_code") }}</button>
    </div>
    <div class="col-12 col-xl-6 d-none d-xl-block">
      <ExampleScreen :disabled="service_status === 3" :title="blackout_title" :opacityValue="blackout_range" :text="blackout_text" />
    </div>
    <alert v-if="$alert.title" />
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="platform" :showSettingsLink="false" @closeModal="closeModal" />
    </modal>
  </div>
</template>
<script>
import siteSettingsService from "@/api/siteSettingsService";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ServiceWorker from "@/components/PlatformSettings/ServiceWorker.vue";
import InformationBlock from "@/components/InformationBlock.vue";
import DelaySettings from "../DelaySettings.vue";
import BlackoutSettings from "../BlackoutSettings.vue";
import ExampleScreen from "../ExampleScreen.vue";
import SubscriptionRequest from "../SubscriptionRequest.vue";
import DisplaySettings from "../DisplaySettings.vue";
import { mapGetters } from "vuex";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";

export default {
  name: "NativeWebPush",
  props: {
    platform: {
      type: Object,
    },
  },
  components: {
    DisplaySettings,
    ServiceWorker,
    DelaySettings,
    BlackoutSettings,
    ExampleScreen,
    SubscriptionRequest,
    InformationBlock,
  },
  mixins: [validationMixin, platformSettingsGetCode],
  validations: {
    service_name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(120),
    },
    blackout_title: {
      minLength: minLength(3),
      maxLength: maxLength(120),
    },
    blackout_text: {
      minLength: minLength(3),
      maxLength: maxLength(250),
    },
  },
  data() {
    return {
      displaySettings: {
        disableDeviceSell: [],
        disableOsSell: [],
        notShowReff: false,
        disableYandex: false,
      },
      service_name: "",
      service_status: 0,
      delay_value: 0,
      blackout_title: "",
      blackout_text: "",
      blackout_range: 0,
      checkSubscription: false,
      isEnabled: null,
      osVersions: [],
      serviceWorkerError: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    siteSettingsService
      .getOsVersions()
      .then((res) => {
        if (res && res.status === 200) {
          this.osVersions = res.data;
          return siteSettingsService.getNativeWebPush(id);
        }
      })
      .then((resp) => {
        if (resp.data.length === 0) {
          console.log("No data");
        } else {
          this.delay_value = resp.data.delay;
          this.blackout_range = resp.data.dimming;
          this.blackout_title = resp.data.caption;
          this.blackout_text = resp.data.description;
          this.checkSubscription = resp.data.lazy_activation;

          this.displaySettings = {
            disableDeviceSell: resp.data.display_settings?.disable_devices || [],
            disableOsSell: this.osVersions.filter((el) => resp.data.display_settings?.disable_os.includes(el.value)) || [],
            notShowReff: resp.data.display_settings?.disable_no_referer || false,
            disableYandex: resp.data.display_settings?.disable_yandex_browser || false,
          };

          if (resp.data.worker_name) {
            this.service_name = resp.data.worker_name;
          }
        }
      });
  },
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
  },
  methods: {
    setBlackoutTitle(val) {
      this.blackout_title = val;
    },
    setBlackoutText(val) {
      this.blackout_text = val;
    },
    setStatus(val) {
      this.service_status = val;
    },
    sendRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.service_status !== 2) {
        this.$refs["serviceWorker"].$el.scrollIntoView({ behavior: "smooth" });
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("platform.settings.service_worker_error"),
        });
        this.serviceWorkerError = true;
        return;
      }
      this.serviceWorkerError = false;
      const id = this.$route.params.id;
      const payload = {
        enable: true,
        id: this.$route.params.id,
        worker_name: this.service_name,
        delay: this.delay_value,
        dimming: this.blackout_range,
        caption: this.blackout_title,
        description: this.blackout_text,
        lazy_activation: this.checkSubscription,

        display_settings: this.featureFlags.includes("enable-display-settings")
          ? {
              disable_devices: this.displaySettings.disableDeviceSell,
              disable_os: this.displaySettings.disableOsSell.map((el) => el.value) || [],
              disable_no_referer: this.displaySettings.notShowReff,
              disable_yandex_browser: this.displaySettings.disableYandex,
            }
          : undefined,
      };

      siteSettingsService.setNativeWebPush(id, payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: `${this.$t("save_successfully")}`,
          });
          this.allowButton = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

.disable-delay {
  opacity: 0.6;
}

.attention-field {
  position: relative;
  background: 0;
  padding: 0 0 0 26px;
  .message-icon {
    position: absolute;
    left: 0;
    fill: #5183cf;
  }
}
</style>
