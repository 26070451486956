<template>
  <div class="col-12">
    <h1>{{ $t("platform.create_new") }}</h1>
    <div class="type-selection">
      <div class="type-selection__body">
        <div class="row pt-2 flex-nowrap">
          <div @click="$emit('setNav', nav.value)" class="col-auto check-item" v-for="nav in navs" :key="nav.id">
            <input type="checkbox" :checked="nav.selected" :ref="`navCheckbox${nav.name}`" />
            <span class="check-item__content" :class="{ active: currentNav.name === nav.name }">
              <span @click.stop="toggleNav(nav)" class="check-item__visible"></span>
              <span class="check-item__txt">{{ nav.name }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5 d-flex align-items-center havbar-header-wrapper">
      <h2 class="d-flex align-items-center m-0">
        {{ currentNav.name }}
        <span class="is-enabled" :class="currentNavSelected ? 'pos' : 'neg'">
          {{ currentNavSelected ? $t("turn_on") : $t("turn_off") }}
        </span>
      </h2>
      <button v-if="currentNav && links.en[currentNav.title]" @click="getLink" class="link-button">
        {{ $t("platform.settings.more_about_format") }}
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.7 0C7.39624 0 7.15 0.246243 7.15 0.55C7.15 0.853757 7.39624 1.1 7.7 1.1H9.12218L4.28609 5.93609C4.0713 6.15088 4.0713 6.49912 4.28609 6.71391C4.50088 6.9287 4.84912 6.9287 5.06391 6.71391L9.9 1.87782V3.3C9.9 3.60376 10.1462 3.85 10.45 3.85C10.7538 3.85 11 3.60376 11 3.3V0.55C11 0.246243 10.7538 0 10.45 0H7.7ZM1.1 1.65C1.1 1.34624 1.34624 1.1 1.65 1.1H4.4C4.70376 1.1 4.95 0.853757 4.95 0.55C4.95 0.246243 4.70376 0 4.4 0H1.65C0.73873 0 0 0.73873 0 1.65V9.35C0 10.2613 0.73873 11 1.65 11H9.35C10.2613 11 11 10.2613 11 9.35V6.56333C11 6.25958 10.7538 6.01333 10.45 6.01333C10.1462 6.01333 9.9 6.25958 9.9 6.56333V9.35C9.9 9.65376 9.65376 9.9 9.35 9.9H1.65C1.34624 9.9 1.1 9.65376 1.1 9.35V1.65Z"
            fill="#317475"
          />
        </svg>
      </button>
    </div>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import siteSettingsService from "@/api/siteSettingsService";
import { mapGetters } from "vuex";

export default {
  name: "",
  props: {
    currentNavValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentNav: null,
      links: {
        ru: {
          native_web_push: "https://intercom.help/partnershouse/en/articles/8489648-%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-push-%D0%BF%D0%BE%D0%B4%D0%BF%D0%B8%D1%81%D0%BA%D0%B0",
          in_page: "https://intercom.help/partnershouse/en/articles/8489779-%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-in-page-push",
          on_click: "https://intercom.help/partnershouse/en/articles/8489800-%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-onclick-%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82",
          two_click_push: "https://intercom.help/partnershouse/en/articles/8937364-%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-2-click-push",
          zero_click_push: "https://intercom.help/partnershouse/en/articles/9465334-%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-0click-push",
        },
        en: {
          native_web_push: "https://intercom.help/partnershouse/en/articles/8512898-what-is-a-push-subscription",
          in_page: "https://intercom.help/partnershouse/en/articles/8512929-what-is-an-in-page-push",
          on_click: "https://intercom.help/partnershouse/en/articles/8512965-what-is-onclick",
          two_click_push: "https://intercom.help/partnershouse/en/articles/8937373-what-is-2-click-push",
          zero_click_push: "https://intercom.help/partnershouse/en/articles/9465359-what-is-0click",
        },
      },
      navs: [
        {
          id: 1,
          name: "Native Web Push",
          value: "NativeWebPush",
          title: "native_web_push",
          selected: false,
        },
        // { id: 2, name: "Http Web Push", title: "http_web_push" value: "HttpWebPush" },
        {
          id: 2,
          name: "InPage",
          value: "InPage",
          title: "in_page",
          selected: false,
        },
        {
          id: 3,
          name: "OnClick",
          value: "OnClick",
          title: "on_click",
          selected: false,
        },
        {
          id: 5,
          name: "0Click Push",
          value: "ZeroClick",
          title: "zero_click_push",
          selected: false,
        },

        // {
        //   id: 4,
        //   name: "2Click Push",
        //   value: "ClickPush",
        //   title: "two_click_push",
        //   selected: false,
        // },
        // { id: 5, name: "Back Button Web Push", title: "back_button_web_push" value: "BackButtonWebPush" },
        // { id: 6, name: "OnClick Web Push", title: "onclick_web_push" value: "OnClickWebPush" },
        // { id: 7, name: "iOS Calendar", title: "ios_calendar" value: "IOSCalendar" },
        // { id: 8, name: "Native", title: "native", value: "Native" },
      ],
    };
  },
  watch: {
    currentNavValue: {
      immediate: true,
      handler() {
        this.setCurrentNav();
      },
    },
    featureFlags: {
      immediate: true,
      handler(val) {
        if (val.some((el) => el === "2clickpush") && this.navs.findIndex((el) => el.id === 4) === -1) {
          this.navs.splice(3, 0, {
            id: 4,
            name: "2Click Push",
            value: "ClickPush",
            title: "two_click_push",
            selected: false,
          });
          this.setCurrentNav();
        }
      },
    },
  },
  created() {
    const id = this.$route.params.id;
    siteSettingsService.getFormats(id).then((res) => {
      if (res && res.status === 200) {
        this.navs = this.navs.map((el) => {
          if (el.title in res.data) {
            el.selected = res.data[el.title];
          }
          return el;
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
    currentNavSelected() {
      return this.navs.find((el) => el.value === this.currentNav.value).selected;
    },
  },
  methods: {
    setCurrentNav() {
      this.currentNav = this.navs.find((el) => el.value === this.currentNavValue);
    },
    getLink() {
      const locale = this.$i18n.locale === "ru" ? "ru" : "en";
      window.open(this.links[locale][this.currentNav.title], "_blank");
    },
    toggleNav(selectedNav) {
      const input = this.$refs[`navCheckbox${selectedNav.name}`][0];
      const id = this.$route.params.id;
      const payload = {
        title: selectedNav.name,
        enabled: !selectedNav.selected,
      };
      siteSettingsService.setSubAccountFormat(id, payload).then((res) => {
        if (res) {
          if (res.status === 200) {
            this.$alert({
              title: `${this.$t("save_successfully")}`,
              type: "success",
            });
            input.checked = payload.enabled;
            selectedNav.selected = payload.enabled;
          } else {
            this.$alert({
              title: this.$t("sent_error"),
              text: res.message,
            });
          }
          return;
        }
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("server_error"),
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.type-selection {
  margin: 0 0 45px;
  position: relative;
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(270deg, #ffffff 27.63%, rgba(255, 255, 255, 0.24) 61.18%);
    width: 20px;
    pointer-events: none;
  }
  .type-selection__body {
    overflow-x: auto;
    @media screen and (max-width: 1200px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .row {
    margin: 0;
    .col-auto {
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .check-item {
    white-space: nowrap;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1200px) {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.check-item__content.active {
  border-color: #38807d;
}

.havbar-header-wrapper {
  flex-wrap: wrap;
  gap: 2rem 4rem;
}

.is-enabled {
  position: relative;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  padding: 7px 12px;
  border-radius: 5px;
  margin-left: 14px;

  &::after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    z-index: -1;
    left: -5px;
    transform: translateY(-50%) rotate(-45deg);
    border-radius: 2px;
  }

  &.neg {
    background-color: #c54747;

    &::after {
      background-color: #c54747;
    }
  }

  &.pos {
    background-color: #37807d;

    &::after {
      background-color: #37807d;
    }
  }
}
</style>
