<template>
  <div class="fields-section pb-3">
    <div class="row">
      <div class="col-12">
        <div class="form-item">
          <div class="form-item__label info-popup">
            {{ $t("platform.settings.service_name") }}

            <svg
              v-svg
              v-if="description"
              v-tooltip="{
                content: $t(`${description}`),
                trigger: $isMobile ? 'click' : 'hover',
              }"
              symbol="info"
              size="0 0 18 18"
              role="info"
            />
          </div>
          <div class="form-item__field">
            <input
              type="text"
              :disabled="disable"
              :class="{
                invalid: is_service_name_invalid || !error.service_name.maxLength || !error.service_name.minLength,
              }"
              v-model="data"
            />
            <small v-if="is_service_name_invalid" class="error">{{ $t("platform.settings.check_service_name") }} </small>
            <small v-else-if="!error.service_name.minLength && data !== ''" class="error">{{ $t("min_len_pass", { number: "3" }) }}</small>
            <small v-else-if="!error.service_name.maxLength && data !== ''" class="error">{{ $t("max_len_pass", { number: "120" }) }}</small>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-item pb-0">
          <div class="form-item__field">
            <div class="row align-items-center pt-2">
              <div class="col-12 col-md-4 mb-4">
                <button :disabled="!data.length || $v.$invalid || is_service_name_invalid || !error.service_name.maxLength || !error.service_name.minLength" @click.prevent="downloadFile" class="btn _border w-100">
                  <span class="icon-download mr-2">
                    <svg v-svg symbol="download" size="0 0 17 17" role="info" />
                  </span>
                  {{ $t("download") }}
                </button>
              </div>
              <div class="col-12 col-md-8 mb-4">
                <div class="message">
                  <InformationBlock>
                    {{ $t("platform.settings.download_service_file") }}
                  </InformationBlock>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-item pb-0">
          <div class="form-item__label">
            {{ $t("platform.settings.check_service") }}
          </div>
          <div class="form-item__field mb-0">
            <div class="row align-items-center pt-2">
              <div class="col-12 col-md-4 mb-4">
                <button @click.prevent="checkServiceWorker" :disabled="!data.length || $v.$invalid || is_service_name_invalid || !error.service_name.maxLength || !error.service_name.minLength" class="btn _border _green w-100">
                  {{ $t("platform.site.confirm_site.check") }}
                </button>
              </div>
              <div class="col-12 col-md-auto mb-4">
                <div v-if="status === 1" class="status">
                  <div class="status__label">{{ $t("platform.site.confirm_site.status") }}:</div>
                  <div class="status__result">
                    <div class="status__load">
                      {{ $t("platform.site.confirm_site.checking") }}
                    </div>
                  </div>
                </div>
                <div v-else-if="status === 2" class="status">
                  <div class="status__label">{{ $t("platform.site.confirm_site.status") }}:</div>
                  <div class="status__result">
                    <div class="status__confirmed">
                      <span class="icon-confirm">
                        <svg v-svg symbol="confirm" size="0 0 22 22" role="info" />
                      </span>
                      {{ $t("platform.site.confirm_site.confirm") }}
                    </div>
                  </div>
                </div>
                <div v-else-if="status === 3" class="status">
                  <div class="status__label">
                    <span> {{ $t("platform.site.confirm_site.status") }}: </span>
                  </div>
                  <div class="status__result">
                    <div class="status__error">
                      <span class="icon-no-confirm">
                        <svg v-svg symbol="no-confirm" size="0 0 22 22" role="info" />
                      </span>
                      <span>
                        {{ error_message }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import siteSettingsService from "@/api/siteSettingsService";
import InformationBlock from "@/components/InformationBlock.vue";

export default {
  name: "ServiceWorker",
  mixins: [validationMixin],
  components: { InformationBlock },
  validations: {
    data: {
      required,
      valid: function (value) {
        // return /^[A-Za-z0-9_\-.]+$/.test(value); - if want to add "-" and "_"
        return /^[A-Za-z0-9.]+$/.test(value);
      },
    },
  },
  props: {
    status: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    error: {
      type: Object,
      default: null,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error_message: "",
      is_service_name_invalid: false,
    };
  },
  watch: {
    data() {
      const regex = /^[\x20-\x7e]*$/;
      if (regex.test(this.data)) this.is_service_name_invalid = false;
      else this.is_service_name_invalid = true;
    },
    name() {
      this.$emit("setStatus", 0);
    },
  },
  computed: {
    data: {
      get() {
        return this.name;
      },
      set(newVal) {
        if (this.disable) return;
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    downloadFile() {
      const regex = /^[\x20-\x7e]*$/;

      if (!this.data.length) return;

      const payload = {
        name: this.data,
        id: this.$route.params.id,
      };

      if (!regex.test(this.data)) {
        this.is_service_name_invalid = true;
        return;
      }
      siteSettingsService.getServiceWorker(payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("auth.sent_error"),
            text: res.message,
          });
        } else if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.data}.js`);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    checkServiceWorker() {
      // if (this.disable) return;
      if (this.data.length) {
        this.$emit("setStatus", 1);
        const payload = {
          id: this.$route.params.id,
          worker_name: this.data,
        };
        siteSettingsService.checkServiceWorker(payload).then((res) => {
          console.log(res);
          if (res) {
            if (res.type === "Error") {
              this.$emit("setStatus", 3);
              this.error_message = res.message;
            } else if (res.type === "Success") {
              this.$emit("setStatus", 2);
            }
          } else {
            this.error_message = this.$t("sent_error");
            this.$emit("setStatus", 3);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.message-icon {
  position: absolute;
  left: 0;
  fill: #9b9ba4;
}

input:disabled {
  cursor: not-allowed;
}
</style>
