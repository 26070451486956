<template>
  <div class="display-settings">
    <h4>{{ $t("platform.settings.display_settings") }}</h4>
    <div class="form-item__label mt-4">
      {{ $t("platform.settings.disable_sell_for_devices") }}
    </div>
    <div class="form-item__field">
      <custom-multiselect
        :list="disableDevices"
        v-model="displaySettings.disableDeviceSell"
        :allowEmpty="true"
        :multiply="true"
        :class="{ disabled_option: disableDeviceOption }"
      >
        <template v-slot:block="props">
          <span>
            {{ props.value }}
          </span>
        </template>
        <template v-slot:list="props">
          <span>
            {{ props.value }}
          </span>
        </template>
      </custom-multiselect>
    </div>
    <div class="form-item__label">
      {{ $t("platform.settings.disable_sell_for_os") }}
    </div>
    <div class="form-item__field">
      <custom-multiselect
        :list="disableOsVersions"
        v-model="displaySettings.disableOsSell"
        :allowEmpty="true"
        :multiply="true"
        :class="{ disabled_option: disableOption }"
      >
        <template v-slot:block="props">
          <span>
            {{ props.value.title }}
          </span>
        </template>
        <template v-slot:list="props">
          <span>
            {{ props.value.title }}
          </span>
        </template>
      </custom-multiselect>
    </div>
    <br />
    <CheckBoxWrapper
      v-model="displaySettings.notShowReff"
      :text="$t('platform.settings.not_show_without_ref')"
    />
    <br /><br />
    <CheckBoxWrapper
      v-model="displaySettings.disableYandex"
      :text="$t('platform.settings.disable_ads_for_yandex')"
    />
    <br /><br />
    <CheckBoxWrapper
      v-if="onclickOnly"
      v-model="displaySettings.linkOnly"
      :text="$t('platform.settings.work_onlick_only')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckBoxWrapper from "../CheckBoxWrapper.vue";

export default {
  props: {
    displaySettings: Object,
    onclickOnly: {
      type: Boolean,
      default: false,
    },
    disableOsVersions: Array,
  },
  components: { CheckBoxWrapper },
  watch: {
    "displaySettings.disableOsSell": function (val) {
      val.length == 6
        ? (this.disableOption = true)
        : (this.disableOption = false);
    },
    "displaySettings.disableDeviceSell": function (val) {
      val.length == 2
        ? (this.disableDeviceOption = true)
        : (this.disableDeviceOption = false);
    },
  },
  data: () => ({
    disableOption: false,
    disableDeviceOption: false,
    disableDevices: ["Mobile", "Desktop", "Tablet"],
  }),
  computed: {
    ...mapGetters({
      selectOS: "statistics/getOS",
    }),
  },
};
</script> 

<style scoped lang="scss">
.display-settings {
  padding: 10px 0 20px;
  h4 {
    font-size: 20px;
  }
}

.multiselect.disabled_option
  ::v-deep
  .multiselect__option:not(.multiselect__option--selected) {
  pointer-events: none;
  cursor: not-allowed;
  background: #c6c6c6 !important;
  color: #838383 !important;
}
</style>