var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-item pb-2",class:{ disabled: _vm.disabled }},[_c('label',{staticClass:"form-item__label info-popup"},[_vm._v(" "+_vm._s(_vm.$t("platform.settings.blackout_title"))+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"},{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('platform.settings.description.native_web_push.blackout_title'),
          trigger: _vm.$isMobile ? 'click':'hover',
        }),expression:"{\n          content: $t('platform.settings.description.native_web_push.blackout_title'),\n          trigger: $isMobile ? 'click':'hover',\n        }"}],attrs:{"symbol":"info","size":"0 0 18 18","role":"info"}})]),_c('div',{staticClass:"form-item__field"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.blackout_title),expression:"blackout_title",modifiers:{"trim":true}}],attrs:{"disabled":_vm.disabled,"type":"text"},domProps:{"value":(_vm.blackout_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.blackout_title=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
          _vm.error.blackout_title.$error &&
          (!_vm.error.blackout_title.minLength || _vm.blackout_title === '')
        )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("min_len_pass", { number: "3" })))]):_vm._e(),(
          _vm.error.blackout_title.$error &&
          (!_vm.error.blackout_title.maxLength || _vm.blackout_title === '')
        )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("max_len_pass", { number: "120" })))]):_vm._e()])]),_c('div',{staticClass:"form-item pb-2",class:{ disabled: _vm.disabled }},[_c('label',{staticClass:"form-item__label info-popup"},[_vm._v(" "+_vm._s(_vm.$t("platform.settings.blackout_text"))+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"},{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('platform.settings.description.native_web_push.blackout_text'),
          trigger: _vm.$isMobile ? 'click':'hover',
        }),expression:"{\n          content: $t('platform.settings.description.native_web_push.blackout_text'),\n          trigger: $isMobile ? 'click':'hover',\n        }"}],attrs:{"symbol":"info","size":"0 0 18 18","role":"info"}})]),_c('div',{staticClass:"form-item__field"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.blackout_text),expression:"blackout_text",modifiers:{"trim":true}}],attrs:{"disabled":_vm.disabled,"type":"text"},domProps:{"value":(_vm.blackout_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.blackout_text=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
          _vm.error.blackout_text.$error &&
          (!_vm.error.blackout_text.minLength || _vm.blackout_text === '')
        )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("min_len_pass", { number: "3" })))]):_vm._e(),(
          _vm.error.blackout_text.$error &&
          (!_vm.error.blackout_text.maxLength || _vm.blackout_text === '')
        )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("max_len_pass", { number: "120" })))]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }