<template>
  <div class="content content_in">
    <div class="row">
      <NavBar :currentNavValue="currentNav" @setNav="setCurrentNav" />
      <component :platform="platform" :is="currentNav"></component>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/PlatformSettings/NavBar.vue";
import NativeWebPush from "@/components/PlatformSettings/Blocks/NativeWebPush.vue";
import OnClick from "@/components/PlatformSettings/Blocks/OnClick.vue";
import InPage from "@/components/PlatformSettings/Blocks/InPage.vue";
import ClickPush from "@/components/PlatformSettings/Blocks/ClickPush.vue";
import ZeroClick from "@/components/PlatformSettings/Blocks/ZeroClick.vue";
import subaccsService from "@/api/subaccsService";

export default {
  name: "PlatformSettings",
  components: { NavBar, NativeWebPush, OnClick, InPage, ClickPush, ZeroClick },
  data() {
    return {
      currentNav: null,
      platform: null,
    };
  },
  created() {
    this.currentNav = localStorage.getItem("current-format") ?? "NativeWebPush";
  },
  methods: {
    setCurrentNav(nav) {
      localStorage.setItem("current-format", nav);
      this.currentNav = nav;
    },
  },
  mounted() {
    subaccsService.getSubAcc(this.$route.params.id).then((res) => {
      if (res && res.status === 200) {
        this.platform = res.data;
        this.platform.id = this.$route.params.id;
      }
    });
  },
};
</script>
