<template>
  <div class="col-12">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-6">
            <Counter v-model="delay" :max="60" :placeholder="$t('sec') + '.'" :min="0" :title="'platform.settings.inpage.delay'" :description="'platform.settings.description.inpage.delay'" />
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-item__label info-popup">
              {{ $t("platform.settings.inpage.position") }}

              <svg
                v-svg
                v-tooltip="{
                  content: $t('platform.settings.description.inpage.position'),
                  trigger: $isMobile ? 'click' : 'hover',
                }"
                symbol="info"
                size="0 0 18 18"
                role="info"
              />
            </label>
            <custom-multiselect class="multiselect-wrapper multiselect-w-100" v-model="position" :list="positionOptions" :allowEmpty="false">
              <template v-slot:block="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
              <template v-slot:list="props">
                <span>
                  {{ props.value.value }}
                </span>
              </template>
            </custom-multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 space-for-sm">
            <Counter v-model="delay_after_click" :max="600" :min="0" :placeholder="$t('sec') + '.'" :title="'platform.settings.inpage.pause_after_click'" :description="'platform.settings.description.inpage.pause_after_click'" />
          </div>
          <div class="col-12 col-lg-6">
            <Counter v-model="delay_after_close" :max="600" :placeholder="$t('sec') + '.'" :min="0" :title="'platform.settings.inpage.pause_after_close'" :description="'platform.settings.description.inpage.pause_after_close'" />
          </div>
          <div class="col-12">
            <DisplaySettings v-if="featureFlags.includes('enable-display-settings')" :displaySettings="displaySettings" :disableOsVersions="osVersions" />
          </div>
        </div>
        <button @click.prevent="sendRequest" class="btn mb-2 _mob100">
          {{ $t("save") }}
        </button>
        <button @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green mb-2 ml-2 _mob100"><svg v-svg size="0 0 20 13" symbol="code"></svg> {{ $t("platforms.table.get_code") }}</button>
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="banner-wrapper">
          <span class="title">{{ $t("platform.settings.inpage.title") }}</span>
          <span class="info">{{ $t("platform.settings.inpage.description") }}</span>
          <img src="@/assets/images/in-page-banner.webp" class="banner" />
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="platform" :showSettingsLink="false" @closeModal="closeModal" />
    </modal>
  </div>
</template>

<script>
import Counter from "../Counter.vue";
import DisplaySettings from "../DisplaySettings.vue";
import siteSettingsService from "@/api/siteSettingsService";
import { mapGetters } from "vuex";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";

export default {
  name: "InPage",
  props: {
    platform: {
      type: Object,
    },
  },
  components: {
    Counter,
    DisplaySettings,
  },
  mixins: [platformSettingsGetCode],
  computed: {
    ...mapGetters({
      featureFlags: "user/getFeatureFlags",
    }),
  },
  data() {
    return {
      delay: 0,
      position: { id: 4, value: "bottom-right" },
      delay_after_click: 10,
      delay_after_close: 10,
      positionOptions: [
        { id: 1, value: "top-left" },
        { id: 2, value: "top-right" },
        { id: 3, value: "bottom-left" },
        { id: 4, value: "bottom-right" },
      ],
      displaySettings: {
        disableDeviceSell: [],
        disableOsSell: [],
        notShowReff: false,
        disableYandex: false,
      },
      osVersions: [],
    };
  },

  mounted() {
    const id = this.$route.params.id;

    siteSettingsService
      .getOsVersions()
      .then((res) => {
        if (res && res.status === 200) {
          this.osVersions = res.data;
          return siteSettingsService.getInPage(id);
        }
      })
      .then((resp) => {
        if (resp.data.length === 0) {
          console.log("No data");
        } else {
          this.delay = resp.data.delay;
          this.position.value = resp.data.position;
          this.delay_after_click = resp.data.delay_after_click;
          this.delay_after_close = resp.data.delay_after_close;

          this.displaySettings = {
            disableDeviceSell: resp.data.display_settings?.disable_devices || [],
            disableOsSell: this.osVersions.filter((el) => resp.data.display_settings?.disable_os.includes(el.value)) || [],

            notShowReff: resp.data.display_settings?.disable_no_referer || false,
            disableYandex: resp.data.display_settings?.disable_yandex_browser || false,
          };
        }
      });
  },
  methods: {
    sendRequest() {
      const id = this.$route.params.id;
      const payload = {
        delay: this.delay,
        position: this.position.value,
        delay_after_click: this.delay_after_click,
        delay_after_close: this.delay_after_close,

        display_settings: this.featureFlags.includes("enable-display-settings")
          ? {
              disable_devices: this.displaySettings.disableDeviceSell || [],
              disable_os: this.displaySettings.disableOsSell.map((el) => el.value) || [],
              disable_no_referer: this.displaySettings.notShowReff,
              disable_yandex_browser: this.displaySettings.disableYandex,
            }
          : undefined,
      };

      siteSettingsService.setInPage(id, payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        } else if (res && res.status === 500) {
          this.$alert({
            title: this.$t("sent_error"),
            text: this.$t("save_failed"),
          });
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: `${this.$t("save_successfully")}`,
          });
          this.allowButton = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-container {
  display: flex;
}

@media screen and (max-width: 991px) {
  .space-for-sm {
    padding-top: 20px;
  }
}

.banner-wrapper {
  position: relative;
  span.title,
  span.info {
    position: absolute;
    top: 13vw;
    left: 9.5vw;
    font-size: 15px;
    font-weight: 600;
    @media screen and (max-width: 997px) {
      top: 32.5vw;
      left: 22.5vw;
    }
    @media screen and (min-width: 998px) and (max-width: 1200px) {
      top: 15.5vw;
      left: 12vw;
    }
  }
  span.info {
    top: 14.5vw;
    font-weight: 400;
    font-size: 14px;
    @media screen and (max-width: 997px) {
      top: 36.5vw;
    }
    @media screen and (min-width: 998px) and (max-width: 1200px) {
      top: 18vw;
    }
  }
}
img.banner {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.multiselect-wrapper {
  ::v-deep {
    .multiselect__content-wrapper {
      @media (min-width: 768px) {
        width: max-content;
        max-width: max-content;
      }
      @media (max-width: 768px) {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
      }
    }
    .multiselect__tags {
      .multiselect__single {
        position: relative;
        white-space: nowrap;
        overflow: hidden;

        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 100%;
          background: #f5f5f5;
          right: -10px;
          box-shadow: -5px 0px 20px 20px #f5f5f5;
        }
      }
    }
  }
}
.multiselect-w-100 {
  ::v-deep {
    .multiselect__content-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
